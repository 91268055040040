import { graphql } from 'gatsby'
import get from 'lodash/get'
import { CFResponsiveAsset, ResponsiveImage } from './types/gatsby'
import { ContentfulTypePerson, Person, transform as toPerson } from './person'
import { LinkItem, ContentfulTypeLink, transform as toLinkItem } from './link'

export type ContentfulPageTeam = {
  id: string
  headerTitle: string
  headerSubtitle: string
  headerDescription: string
  headerImages: CFResponsiveAsset[]
  teamMembers: ContentfulTypePerson[]
  ctaHeading: string
  ctaLinks: ContentfulTypeLink[]
  ctaImage: CFResponsiveAsset
}

export type TeamPage = {
  id: string
  header: {
    title: string
    subtitle: string
    description: string
    images: ResponsiveImage[]
  }
  teamMembers: Person[]
  cta: {
    heading: string
    links: LinkItem[]
    image: ResponsiveImage
  }
}

export const fragment = graphql`
  fragment TeamPageFields on ContentfulPageTeam {
    id
    headerTitle
    headerSubtitle
    headerDescription
    headerImages {
      ...ResponsiveAssetFields
    }
    teamMembers {
      ...PersonFields
    }
    ctaHeading
    ctaLinks {
      ...LinkFields
    }
    ctaImage {
      ...ResponsiveAssetFields
    }
  }
`

// FIXME: these are a mess
const toResponsiveImage = (img: CFResponsiveAsset) =>
  !img
    ? null
    : {
        sizes: img.sizes,
        alt: img.title || img.description,
      }

export const transform = (node: ContentfulPageTeam): TeamPage => ({
  id: node.id,
  header: {
    title: get(node, 'headerTitle', ''),
    subtitle: get(node, 'headerSubtitle', ''),
    description: get(node, 'headerDescription', ''),
    images: get(node, 'headerImages', []),
  },
  teamMembers: get(node, 'teamMembers', []).map(toPerson),
  cta: {
    heading: get(node, 'ctaHeading', ''),
    links: get(node, 'ctaLinks', []).map(toLinkItem),
    image: toResponsiveImage(get(node, 'ctaImage', {})),
  },
})
