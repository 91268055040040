import * as React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { graphql } from 'gatsby'

import { CoverImage } from '../components/image'
import { IconButton } from '../components/button'
import { Link } from '../components/link'
import { JoinTheTeam } from '../components/section'
import { Twitter, Medium, LinkedIn, ExternalLink } from '../components/icons'
import { Person } from '../data/person'
import { transform as toTeamPage, ContentfulPageTeam } from '../data/team-page'
import { useDynamicTracking } from '../hooks'

const SOCIAL_ICONS = {
  link: <ExternalLink />,
  twitter: <Twitter />,
  medium: <Medium />,
  'linked-in': <LinkedIn />,
}

interface Props {
  data: {
    page: {
      edges: { node: ContentfulPageTeam }[]
    }
  }
}

const StyledOurTeamSection = styled.section`
  padding-top: 60px;

  .team-member {
    margin-bottom: 48px;

    &--large {
      margin-bottom: 52px;
    }

    .cover-image {
      width: calc(100% + 16px);
    }
  }

  h2,
  h2 + div {
    grid-column: 1 / -1;
  }

  @media screen and (min-width: 768px) {
    padding-top: 90px;

    h2,
    h2 + div {
      grid-column: 2 / -2;
    }

    h2 + div {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 36px;
      grid-row-gap: 42px;
    }

    .team-member {
      grid-column: span 3;
      margin-bottom: 0;

      &--large {
        margin-bottom: 32px;
      }

      .cover-image {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 140px;

    h2,
    h2 + div {
      grid-column: 3 / -3;
    }

    .team-member {
      grid-column: span 2;

      &--large {
        grid-column: span 3;
      }
    }
  }

  @supports (writing-mode: vertical-lr) {
    h2 {
      writing-mode: vertical-lr;
      grid-column: 1;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & + div {
        grid-column: 2 / -1;
      }

      & > span {
        position: sticky;
        top: 72px;
      }
    }

    @media screen and (min-width: 768px) {
      h2 {
        grid-column: 2 / 4;

        & > span {
          top: 96px;
        }
      }

      h2 + div {
        grid-column: 5 / -2;
      }
    }

    @media screen and (min-width: 1024px) {
      h2 {
        grid-column: 3 / 5;

        & > span {
          top: 140px;
        }
      }

      h2 + div {
        grid-column: 7 / -3;
      }
    }
  }
`

export default class OurTeamPage extends React.Component<Props, {}> {
  public render() {
    const { data } = this.props
    const [page] = data.page.edges.map(({ node }) => toTeamPage(node))
    const all = page.teamMembers
    const [founders, team] = [
      all.filter((a: Person) => a.title.includes('founder')),
      all.filter((a: Person) => !a.title.includes('founder')),
    ]
    return (
      <>
        <section className="bg-dark-navy color-white">
          <div className="container container--grid">
            <div
              className="container__row"
              style={{ textAlign: 'center', margin: '48px 0' }}
            >
              {/* <span className="type-label-lg">{page.header.subtitle}</span> */}
              <h1 className="type-h1">{page.header.title}</h1>
              <p
                className="type-p-lg"
                style={{
                  width: '80%',
                  maxWidth: 700,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {page.header.description}
              </p>
            </div>
          </div>
        </section>
        <StyledOurTeamSection>
          <div className="container container--grid">
            <h2 className="type-h-magic">
              <span>Our Team</span>
            </h2>
            <div>
              {founders.map(x => (
                <TeamMemberCard key={x.name} person={x} size="large" />
              ))}
              {team.map(x => (
                <TeamMemberCard key={x.name} person={x} />
              ))}
            </div>
          </div>
        </StyledOurTeamSection>
        <JoinTheTeam
          heading={page.cta.heading}
          photo={page.cta.image}
          links={page.cta.links}
        />
      </>
    )
  }
}

function TeamMemberCard({
  person,
  size,
  className,
}: {
  person: Person
  size?: 'default' | 'large'
  className?: string
}) {
  const [onTrack] = useDynamicTracking({
    action: 'View Social Profile',
    category: 'Team Page',
  })
  return (
    <div
      className={cx(
        'team-member',
        { [`team-member--${size}`]: size && size !== 'default' },
        className
      )}
    >
      <CoverImage src={person.photo.src} alt={person.photo.alt} />
      <p className="team-member__title type-mono-md">{person.title}</p>
      <h3
        className={cx('team-member__name', {
          'type-h3': size === 'large',
          'type-h6': size !== 'large',
        })}
      >
        {person.name}
      </h3>
      <p className="team-member__bio type-p-sm">{person.bio}</p>
      <div className="team-member__socials">
        {(person.socials || []).map(s => (
          <IconButton
            key={s.link}
            as={Link}
            to={s.link}
            target="_blank"
            onClick={() => onTrack({ label: `${person.name} - ${s.type}` })}
          >
            {SOCIAL_ICONS[s.type] ? SOCIAL_ICONS[s.type] : SOCIAL_ICONS['link']}
          </IconButton>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query TeamPage {
    page: allContentfulPageTeam {
      edges {
        node {
          ...TeamPageFields
        }
      }
    }
  }
`
